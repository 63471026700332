<template>

  <ul id='menulist' class="list-unstyled ps-2 flex-column mb-auto">
      <div data-bs-parent="#sidebarMenu">
        <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
          <li v-if="isAdmin"><router-link to="/customers" class="link-dark rounded">Klanten</router-link></li>
          <li v-if="isAdmin"><router-link to="/users" class="link-dark rounded">Gebruikers</router-link></li>
          <li><router-link to="/connections" class="link-dark rounded">Koppelingen</router-link></li>
          <li><router-link to="/invoices" class="link-dark rounded">Synchronisatie</router-link></li> 
          <li v-if="isAdmin"><router-link to="/logs" class="link-dark rounded">Logs</router-link></li>
        </ul>
      </div>
  </ul>

  
  <div class="dropdown">
    <hr />
    <a
      href="#"
      class="
        d-flex
        align-items-center
        link-dark
        text-decoration-none
        dropdown-toggle
        p-3
      "
      id="dropdownUser2"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div id='profileimage' class='rounded-circle me-2 bg-primary text-white text-center'>{{getInitials}}</div>
      <!-- <img
        src="/img/kees.jpg"
        alt=""
        width="32"
        height="32"
        class="rounded-circle me-2"
      /> -->
      <strong>{{getName}}</strong>
    </a>
    <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
      <!-- <li><a class="dropdown-item" href="#">New project...</a></li>
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"></li> -->
      <li><a class="dropdown-item link-dark" href="#" @click.prevent="logout">Log uit</a></li>
    </ul>
  </div>
</template>

<script>
import store from '@/store/user'
import router from '@/router'

import toggle from 'bootstrap'

export default {
  name: "navHeader",
  computed: {
    getName () {
      return store.getters.getName
    },
    isAdmin () {
      return store.getters.isAdmin
    },
    getInitials() {
      let name = store.getters.getName;

      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

      let initials = [...name.matchAll(rgx)] || [];

      let first = initials.shift();
      let second = initials.shift();

      initials = first[1]; //+ second[1];

      return initials;
    }
  },
  methods: {
    logout() {
      store.dispatch('userLogout')
      router.push('/')
    }
  },
  watch:{
    $route (to, from){
        console.log("route change to", to);
        console.log("route change from", from);
        if(from.name != undefined && from.name != "Callback") {
          console.log("Normal navigation");
          return;
        }


        let active_items = document.querySelectorAll('#menulist .link-dark[href="' + to.href + '"]');
        console.log(active_items, "Length: ", active_items.length);
        if(active_items.length > 0) {
          let active_menu = active_items[0].parentNode.parentNode.parentNode;
          active_menu.classList.add("show");
          // active_menu_btn.click();
        }
    }
  }
};
</script>

<style>


.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
</style>

<style scoped>

#sidebarMenu #menulist {
  overflow: auto;
} 

#sidebarMenu li button {
  text-align: left;
}
.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.dropdown-toggle {
  outline: 0;
}

.bi {
  vertical-align: -0.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.btn-toggle {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: 0;
}
.btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, 0.85);
  /* background-color: #d2f4ea; */
  outline: 0;
  box-shadow: none;
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, 0.85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  display: inline-flex;
  padding: 0.1875rem 0.5rem;
  margin-top: 0.125rem;
  margin-left: 1.25rem;
  text-decoration: none;
}
/* .btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
} */

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}
.lh-tight {
  line-height: 1.25;
}

.link-light, .link-light:hover {
  color: rgba(0, 0, 0, 0.3);
  
}

#dropdownUser2 {
  white-space: normal;
}

#profileimage {
  min-width: 32px; 
  line-height: 32px;
}

a.link-dark.router-link-active {
  font-weight: bold;
}
</style>
